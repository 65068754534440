'use strict';

import HSMegaMenu from '../vendor/hs-mega-menu/dist/hs-mega-menu.min.js'
import HSGoTo from '../vendor/hs-go-to/dist/hs-go-to.min.js'
import HSVideoPlayer from '../vendor/hs-video-player/dist/hs-video-player.js'
import HSStickyBlock from '../vendor/hs-sticky-block/dist/hs-sticky-block.min.js'
import { Swiper } from '../../../../node_modules/swiper/swiper-bundle.esm.browser.js'

export default {
  init() {
    var swiperFreshContent = new Swiper('.js-swiper-fresh-content', {
      slidesPerView: 1,
      spaceBetween: 30,
      autoHeight: true,
      pagination: {
        el: '.js-swiper-fresh-content-pagination',
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    });

    var swiperEvents = new Swiper('.js-swiper-events-content', {
      slidesPerView: 1,
      spaceBetween: 30,
      autoHeight: true,
      pagination: {
        el: '.js-swiper-events-content-pagination',
        clickable: true,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
      },
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    new HSMegaMenu('.js-mega-menu', {
      desktop: {
        position: 'left',
      },
    });

    new HSVideoPlayer('.js-inline-video-player');

    // INITIALIZATION OF GO TO
    // =======================================================
    new HSGoTo('.js-go-to');

    // STICKY HEADER
    // =======================================================
    window.addEventListener('scroll', function () {
      const header = document.getElementById('header');
      const logo = document.querySelector('.navbar-brand img');
      const scrollY = window.scrollY || window.pageYOffset;

      if (scrollY > 0) {
        header.classList.add('bg-white');
        header.classList.remove('navbar-light');
        if (logo.src.includes('logo-white-es.svg')) {
          logo.src = logo.src.replace('logo-white-es.svg', 'logo-es.svg');
        }
      } else {
        header.classList.remove('bg-white');
        if (header.classList.contains('transparent-header')) {
          header.classList.add('navbar-light');
          logo.src = logo.src.replace('logo-es.svg', 'logo-white-es.svg');
        }
      }
    });

    // INITIALIZATION OF STICKY BLOCKS
    document.addEventListener('DOMContentLoaded', function() {
      const stickyBlockStartPointEg1 = document.getElementById("stickyBlockStartPointEg1");
      const stickyBlockStartPointContent = document.getElementById("stickyBlockStartPointContent");

      if ( stickyBlockStartPointEg1 !== null && stickyBlockStartPointContent !== null )  {
        const alturaElemento1 = stickyBlockStartPointEg1.clientHeight;
        const alturaElemento2 = stickyBlockStartPointContent.clientHeight;

        // console.log('alturaElemento1', alturaElemento1);
        // console.log('alturaElemento2', alturaElemento2);

        if ( alturaElemento1 < alturaElemento2 ) {
          new HSStickyBlock('.js-sticky-block', {
            targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
          });
        }
      }
    });

    // Add script for lazyload bg images
    let lazyObjectObserver = new IntersectionObserver(function(entries, observer) {
      let bgsrc;
      entries.forEach(function(entry) {
          if (entry.isIntersecting) {
              let lazyObject = entry.target;
              if(!(lazyObject.dataset.lazybg == '')){
                  bgsrc = lazyObject.dataset.lazybg;
                  lazyObject.style.backgroundImage = 'url('+bgsrc+')';
                  lazyObject.classList.remove("lazybg");
                  lazyObject.dataset.lazybg = '';
                  lazyObjectObserver.unobserve(lazyObject);
              }
          }
      });
    },{ rootMargin: "0px 0px 0px 0px" });

    document.addEventListener("DOMContentLoaded", function() {
      var lazyObjects = [].slice.call(document.querySelectorAll(".lazybg"));
      lazyObjects.forEach(function(lazyObject) {
            lazyObjectObserver.observe(lazyObject);
      });
    });

    // Search by Title Controller
    document.addEventListener('DOMContentLoaded', function() {
      const inputField = document.getElementById('search-input'); // Reemplaza con el ID de tu campo de búsqueda
      const resultsContainer = document.getElementById('search-results');

      // Realizar una búsqueda en tiempo real mientras el usuario escribe
      function searchPostsByTerm(searchTerm) {
        const endpoint = inputField.getAttribute('data-endpoint');
        const url = `${endpoint}&search=${encodeURIComponent(searchTerm)}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                // Aquí puedes procesar los datos de los posts y mostrar los resultados en la vista
                displaySearchResults(data);
            })
            .catch(error => {
                console.error('Error al buscar posts:', error);
            });
      }

      // Función para mostrar los resultados de la búsqueda en la vista
      function displaySearchResults(results) {
        const resultsContainer = document.getElementById('search-results'); // Reemplaza con el ID de tu contenedor de resultados
        resultsContainer.innerHTML = ''; // Limpiar resultados anteriores

        const searchTerm = inputField.value.toLowerCase(); // Obtén el término de búsqueda en minúsculas

        const filteredResults = results.filter(post => {
            const postTitle = post.title.toLowerCase(); // Obtén el título de la publicación en minúsculas
            return postTitle.includes(searchTerm); // Filtra las publicaciones que contienen el término de búsqueda en el título
        });

        filteredResults.forEach(post => {
            const postType = post.post_type;
            const postTitle = post.title;
            const postLink = post.link;
            const postVideoID = post.video_id;

            const resultItem = document.createElement('div');
            resultItem.className = 'border-bottom py-2';

            // if (postType === "video" && postVideoID !== null) {
            //     resultItem.innerHTML = `<a class="text-dark" href="https://www.youtube.com/watch?v=${postVideoID}" target="_blank">${postTitle}</a>`;
            // } else {
            //     resultItem.innerHTML = `<a class="text-dark" href="${postLink}">${postTitle}</a>`;
            // }

            resultItem.innerHTML = `<a class="text-dark" href="${postLink}">${postTitle}</a>`;

            resultsContainer.appendChild(resultItem);
        });
      }

      // Escucha los cambios en el campo de búsqueda en tiempo real
      if (inputField !== null && resultsContainer !== null) {
        inputField.addEventListener('input', () => {
          const searchTerm = inputField.value.trim(); // Obtén el término de búsqueda

          // Realiza la búsqueda solo si el término de búsqueda no está vacío y tiene al menos una cierta longitud mínima
          if (searchTerm.length >= 3) {
              searchPostsByTerm(searchTerm);
          } else {
              // Si el término de búsqueda es demasiado corto, puedes mostrar un mensaje o limpiar los resultados.
              resultsContainer.innerHTML = '';
          }
        });
      }
    });

    // Variable de bandera para verificar si los scripts ya se cargaron
    let scriptsLoaded = false;

    // Función para cargar y ejecutar scripts según el atributo data-category
    function loadAndExecuteScriptsByCategory(category) {
      const scripts = document.querySelectorAll('script[data-category="' + category + '"]');

      scripts.forEach(script => {
        if (!script.hasAttribute('data-loaded')) {
          const newScript = document.createElement('script');
          newScript.src = script.src;
          newScript.type = 'text/javascript';
          newScript.onload = () => {
            script.setAttribute('data-loaded', 'true');
            // Agregar lógica adicional para ejecutar el script, si es necesario
          };
          document.head.appendChild(newScript);
        }
      });
    }

    // Agregar un evento de escucha al movimiento del ratón
    document.addEventListener('mousemove', function () {
      // Verificar si los scripts ya se cargaron para evitar cargarlos nuevamente
      if (!scriptsLoaded) {
        // Define la categoría que deseas activar, por ejemplo, "analytics"
        const categoryToActivate = 'analytics';

        // Llama a la función para cargar y ejecutar los scripts de la categoría especificada
        loadAndExecuteScriptsByCategory(categoryToActivate);

        // Marcar los scripts como cargados
        scriptsLoaded = true;
      }
    });


    document.addEventListener("DOMContentLoaded", function () {
      // Verificar si la etiqueta body contiene la clase "miClase"
      if (document.body.classList.contains("template-subservice-page")) {
        // Obtener el elemento que deseas modificar
        const elemento = document.getElementById("serviciosMegaMenu");

        // Agregar una clase al elemento
        elemento.parentNode.classList.add("current-menu-ancestor");
      }
    });

  },
};
